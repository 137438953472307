<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Edit Profil</span>
      </template>
      <!-- <account-setting-general v-if="options.general" :general-data="options.general" /> -->
      <b-card>
        <!-- media -->
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                rounded
                :src="require('@/assets/images/avatars/13-small.png')"
                height="80"
              />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <b-card-text style="margin-bottom: 5px"
              ><b>{{ checkNama }}</b></b-card-text
            >
            <b-card-text
              ><b>{{ checkEmail }}</b></b-card-text
            >
          </b-media-body>
        </b-media>
        <!--/ media -->
        <div v-for="users in users" v-bind:key="users.id">
          <!-- form -->
          <b-form
            method="post"
            enctype="multipart/form-data"
            @submit.prevent="updateKaryawan(users)"
          >
            <b-row>
              <b-col md="6" xl="6" class="mb-1">
                <label for="textarea-default"
                  ><b>Nama</b></label
                >
                <b-form-input
                  id="name"
                  v-model="users.nama"
                  placeholder="Name"
                />
              </b-col>
              <b-col md="6" xl="6" class="mb-1">
                <label for="textarea-default"><b>Kampus</b></label>
                <b-form-input
                  id="kampus"
                  v-model="users.kampus"
                  placeholder="Kampus"
                />
              </b-col>
              <b-col md="6" xl="6" class="mb-1">
                <label for="textarea-default"><b>Jurusan</b></label>
                <b-form-group>
                  <b-form-input v-model="users.jurusan" label="Jurusan" />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6" class="mb-1">
                <label for="textarea-default"><b>Telepon</b></label>
                <b-form-group>
                  <b-form-input v-model="users.telepon" label="Telepon" />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6" class="mb-1">
                <label for="textarea-default"><b>Jenjang</b></label>
                <b-form-select
                  id="jenjang"
                  v-model="users.jenjang"
                  :options="option_jenjang"
                  placeholder="Jenjang"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6" xl="4" class="mb-1" />
              <b-col md="6" xl="2" class="mb-1">
                <b-button variant="success" style="width: 100%" type="submit">
                  <feather-icon icon="SaveIcon" style="color: white" />
                  <span class="align-middle"
                    ><b style="color: white"> Simpan</b></span
                  >
                </b-button>
              </b-col>
              <b-col md="6" xl="2" class="mb-1">
                <b-button
                  variant="danger"
                  style="width: 100%"
                  type="button"
                  @click="cancelButton('info')"
                >
                  <feather-icon icon="XCircleIcon" style="color: white" />
                  <span class="align-middle"
                    ><b style="color: white"> Cancel</b></span
                  >
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <br />
      </b-card>
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Information</span>
      </template>

      <!-- <account-setting-information v-if="options.info" :information-data="options.info" /> -->
      <b-card>
        <b-col xl="12" md="6">
          <b-card-header style="padding-top: 0px; padding-left: 0px">
            <div class="d-flex align-items-center">
              <!-- <b-avatar rounded variant="light-danger" size="34" class="mr-1">
              <feather-icon icon="AlertCircleIcon" size="18" />
            </b-avatar> -->
              <b-card-title>
                <b style="color: firebrick">Informasi</b>
              </b-card-title>
            </div>
          </b-card-header>
          <app-timeline>
            <app-timeline-item
              variant="danger"
              title="Baca Panduan untuk memahami Program Ini"
              style="padding-bottom: 14px"
            />
            <app-timeline-item
              variant="danger"
              title="Telepon: 0819-3220-9501"
              style="padding-bottom: 14px"
            />
            <app-timeline-item
              variant="danger"
              title="Instagram: @mikki_id"
              style="padding-bottom: 14px"
            />
            <app-timeline-item
              variant="danger"
              title="Team Optimal"
              style="padding-bottom: 14px"
            />
          </app-timeline>
        </b-col>
      </b-card>
    </b-tab>

    <b-tab>
      <!-- title -->
      <template #title>
        <router-link to="/">
          <feather-icon icon="ArrowLeftIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Kembali Ke Dashboard</span>
        </router-link>
      </template>
      <!-- <account-setting-information v-if="options.info" :information-data="options.info" /> -->
    </b-tab>
  </b-tabs>
</template>

<script>
// import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
// import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";

import {
  BTab,
  BTabs,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BTab,
    BTabs,
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BToast,
    BCardHeader,
    AppTimeline,
    AppTimelineItem,
    BNav,
    BCardTitle,
    BFormSelect,
    BNavItem,
    BFormTextarea,
  },
  data() {
    return {
      users: [],
      id: this.$route.params.id,
      nama: "",
      jurusan: "",
      kampus: "",
      telepon: "",
      jenjang: "",
      option_jenjang: [
        { text: "Profesi", value: "Profesi" },
        { text: "Diploma 1", value: "D1" },
        { text: "Diploma 2", value: "D2" },
        { text: "Diploma 3", value: "D3" },
        { text: "Diploma 4", value: "D4" },
        { text: "S1", value: "S1" },
        { text: "S2", value: "S2" },
        { text: "S3", value: "S3" },
      ],
      checkEmail: localStorage.getItem("Uemail"),
      checkNama: localStorage.getItem("Unama"),
    };
  },
  created() {
    // this.$http.get(`https://api43-kpi.alvindocs.com/api/user/detail_user/${this.cekUsername}`)
    //   .then(response => {
    //     this.users = response.data
    //   })
  },
  mounted() {
    this.$http
      .get(
        process.env.VUE_APP_BACKEND_URL + `users/check_user/${this.checkEmail}`
      )
      .then((response) => {
        this.users = response.data;
      });
  },
  methods: {
    checkAuth() {
      // CALLBACK
      const authCek = this.cekLevel;
      if (authCek == null) {
        this.$router.push({ name: "sso_login" });
      }
    },
    updateKaryawan(users) {
      Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan Data diakhiri dan dinyatakan valid",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  `users/update_user_account/${this.checkEmail}`,
                {
                  nama: (this.users.nama = users.nama),
                  kampus: (this.users.kampus = users.kampus),
                  jurusan: (this.users.jurusan = users.jurusan),
                  telepon: (this.users.telepon = users.telepon),
                  jenjang: (this.users.jenjang = users.jenjang),
                }
              )
              .then((result) => {
                console.log(this.users.nama);
              });
          }
        })
        .then((result) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Simpan Perubahan Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          this.$router.push({ name: "home" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton(variant = null) {
      this.name = "";
      this.username = "";
      this.departemen = "";
      this.position = "";
      this.pic = "";
      this.pic_2 = "";
      this.level = "";
      this.email = "";
      this.type_user = "";

      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "XCircleIcon",
            text: "Cancel Perubahan Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "home" });
    },
  },
};
</script>
